$(document).ready(function () {
    if(typeof window !== 'undefined' && window.innerWidth < 700) {
        window.location = url + window.location.pathname;
    }

    $('#iframe-meta').attr('src', url + window.location.pathname);


    $('.users-list').slick({
        autoplay: true,
        arrows: false,
        fade: true,
    });

    $(document).on('click', '.user-wrapper', function (e) {
       e.preventDefault();

        $('#iframe-meta').attr('src', url + $(this).attr('data-target'));
        window.history.pushState("", "", $(this).attr('data-target'));
    });

    $(document).on('click', 'a', function (e) {
       e.preventDefault();

        navigate($(this).attr('href'));
    });

    const navigate = (path) => {
        $('#iframe-meta').attr('src', url + path);
        window.history.pushState("", "", path);
    }

    var eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent"
        ? "onmessage"
        : "message";

    eventer(messageEvent, function (e) {

        if (e.origin !== url) return;

        window.history.pushState("", "", e.data);
    });
});
